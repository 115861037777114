import json from '../../package.json';

export interface EnvironmentConfig {
  publicRuntimeConfig: Record<string, string>;
  serverRuntimeConfig: Record<string, string>;
}

const { name: packageName } = json || {};
const serviceName = packageName.replace(/^@.+\//, '');

/**
 * Define the environment configuration for the application here.
 */
const config = () =>
  ({
    publicRuntimeConfig: {
      // Datadog
      DD_APP_ID: process.env.DD_APP_ID || '',
      DD_CLIENT_TOKEN: process.env.DD_CLIENT_TOKEN || '',
      DD_ENV: process.env.DD_ENV || '',
      DD_SERVICE: process.env.DD_SERVICE || serviceName,
      DD_VERSION: String(process.env.DD_VERSION),

      CLIENT_VERSION: String(process.env.CVENT_VERSION),
      LOGIN_URL: String(process.env.LOGIN_URL),
      NODE_ENVIRONMENT: String(process.env.NODE_ENV),

      // Analytics
      CVENT_ANALYTICS_SERVICE: String(process.env.CVENT_ANALYTICS_SERVICE),
      DATA_PLATFORM_WRITE_KEY: String(process.env.DATA_PLATFORM_WRITE_KEY),
      DATA_PLATFORM_URL: String(process.env.DATA_PLATFORM_URL),
      BUSINESS_UNIT: String(process.env.BUSINESS_UNIT),
      PRODUCT: String(process.env.PRODUCT),
      COMPONENT: serviceName
    },
    serverRuntimeConfig: { API_KEY: String(process.env.API_KEY) }
  } satisfies EnvironmentConfig);

declare global {
  interface Window {
    publicRuntimeConfig: Record<string, string>;
  }
}

/**
 * This function returns the environment configuration for the application. It returns the
 * environment configuration defined in /app/api/config/route.ts which appends the publicRuntimeConfig
 * to the window object.
 */
export default function getConfig(): EnvironmentConfig {
  const { publicRuntimeConfig, serverRuntimeConfig } =
    typeof window !== 'undefined'
      ? {
          publicRuntimeConfig: window.publicRuntimeConfig,
          serverRuntimeConfig: {}
        }
      : config();

  return { publicRuntimeConfig, serverRuntimeConfig };
}

const { publicRuntimeConfig } = getConfig();

export const datadogOptions = {
  applicationId: publicRuntimeConfig.DD_APP_ID,
  clientToken: publicRuntimeConfig.DD_CLIENT_TOKEN,
  service: publicRuntimeConfig.DD_SERVICE,
  env: publicRuntimeConfig.DD_ENV,
  version: publicRuntimeConfig.DD_VERSION
};
