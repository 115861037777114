'use client';

import Image from 'next/image';
import Script from 'next/script';
import { IMAGE_DIMENSIONS } from '@/constants/chatWithUsConstants';

interface LiveAgent {
  init(url: string, arg1: string, arg2: string);
  startChat(url: string): void;
  showWhenOnline(name: string, element: HTMLElement | null): void;
  showWhenOffline(name: string, element: HTMLElement | null): void;
}

declare const liveagent: LiveAgent;

interface Props {
  activeImageAltTag: string;
  inactiveImageAltTag: string;
}

export default function ChatWithUs({ activeImageAltTag, inactiveImageAltTag }: Props) {
  return (
    <div style={{ position: 'fixed', bottom: '0', right: '0' }}>
      <Script
        async
        id="chat-with-us"
        src="https://c.la2-c2-ph2.salesforceliveagent.com/content/g/js/57.0/deployment.js"
        strategy="lazyOnload"
        onLoad={() => {
          if (!window._laq) {
            window._laq = [];
          }
          window._laq.push(() => {
            liveagent.showWhenOnline(
              '5732G000000GqPG',
              document.getElementById('liveagent_button_online_5732G000000GqPG')
            );
            liveagent.showWhenOffline(
              '5732G000000GqPG',
              document.getElementById('liveagent_button_offline_5732G000000GqPG')
            );
          });
          liveagent.init('https://d.la2-c2-ph2.salesforceliveagent.com/chat', '572000000008OUb', '00D00000000hXfW');
        }}
      />
      <Image
        id="liveagent_button_online_5732G000000GqPG"
        width={IMAGE_DIMENSIONS.WIDTH}
        height={IMAGE_DIMENSIONS.HEIGHT}
        alt={activeImageAltTag}
        style={{ display: 'none', border: '0px none', cursor: 'pointer' }}
        onClick={() => {
          liveagent.startChat('5732G000000GqPG');
        }}
        src="https://cvent.my.salesforce-sites.com/resource/1592462868000/Chat_Button_RFP_Active"
      />
      <Image
        id="liveagent_button_offline_5732G000000GqPG"
        width={IMAGE_DIMENSIONS.WIDTH}
        height={IMAGE_DIMENSIONS.HEIGHT}
        alt={inactiveImageAltTag}
        style={{ display: 'none', border: '0px none' }}
        onClick={() => {
          window.open('https://csn-chat-support.cvent.com/');
        }}
        src="https://cvent.my.salesforce-sites.com/resource/1592462868000/Chat_button_RFP_inactive"
      />
    </div>
  );
}
