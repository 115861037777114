'use client';

import { initRum } from '@cvent/nextjs/datadog/initRum';
import analytics from '@cvent/analytics';
import getConfig, { datadogOptions } from '@/config/getConfig';

const { publicRuntimeConfig } = getConfig();

function initAnalytics() {
  if (typeof window !== 'undefined') {
    analytics.init({
      customerDataPlatform: {
        writeKey: publicRuntimeConfig.DATA_PLATFORM_WRITE_KEY,
        dataPlaneUrl: publicRuntimeConfig.DATA_PLATFORM_URL
      },
      cventAnalytics: {
        url: publicRuntimeConfig.CVENT_ANALYTICS_SERVICE
      },
      defaultDestinations: ['CventAnalytics'],
      globalActionProperties: {
        appVersion: publicRuntimeConfig.CLIENT_VERSION,
        businessUnit: publicRuntimeConfig.BUSINESS_UNIT,
        product: publicRuntimeConfig.PRODUCT,
        component: publicRuntimeConfig.COMPONENT
      },
      pageTracking: true,
      disabled: process.env.NODE_ENV === 'development'
    });
  }
}

function _initRUM() {
  if (typeof window !== 'undefined') {
    initRum({
      ...datadogOptions,
      sessionReplaySampleRate: 100,
      defaultPrivacyLevel: 'allow',
      traceSampleRate: 100
    });
  }
}

initAnalytics();

// Move Rum initialization here instead of the custom implementation
// initRUM();
