'use client';

import Script from 'next/script';
import { useEffect } from 'react';
import { setOneTrustGlobally } from '@cvent/onetrust-utils';

interface Props {
  isOneTrustEnabled: boolean;
}

export function OneTrust({ isOneTrustEnabled }: Props) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setOneTrustGlobally(isOneTrustEnabled);
    }
  }, [isOneTrustEnabled]);

  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="d44b0ebd-473b-439a-9f06-dd272691519a-test"
      />
      <Script
        type="text/javascript"
        id="optanon-wrapper"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `  
            function OptanonWrapper() {  
              if (!!window.OneTrust) {  
                OneTrust.OnConsentChanged(function(e) {  
                  window.location.reload();  
                });  
              }  
            }  
          `
        }}
      />
    </>
  );
}
