'use client';

import Script from 'next/script';
import { isEUCountry } from '@/utils/cookieHelper';
import { SCRIPT } from '@/constants/cookieConstants';
import { isConsentGivenForCategory } from '@cvent/onetrust-utils';
import { useEffect, useState } from 'react';

type Props = {
  countryCode: string;
  isOneTrustEnabled: boolean;
  isLoggedIn: boolean;
  cventCookieBannerAccepted: boolean;
};

function canAddGoogleMarketingTag(
  isLoggedIn: boolean,
  isOneTrustEnabled: boolean,
  countryCode: string,
  cventCookieBannerAccepted: boolean
): boolean {
  const consent = isOneTrustEnabled ? isConsentGivenForCategory(SCRIPT.GEO_REMARKETING) : cventCookieBannerAccepted;
  return !isEUCountry(countryCode) || consent || isLoggedIn;
}

export function GoogleMarketingTag({
  countryCode,
  isOneTrustEnabled,
  isLoggedIn,
  cventCookieBannerAccepted
}: Props): JSX.Element {
  const [canLoadScript, setCanLoadScript] = useState(false);
  useEffect(() => {
    const googleMarketingTag = canAddGoogleMarketingTag(
      isLoggedIn,
      isOneTrustEnabled,
      countryCode,
      cventCookieBannerAccepted
    );
    setCanLoadScript(googleMarketingTag);
  }, [countryCode, cventCookieBannerAccepted, isLoggedIn, isOneTrustEnabled]);

  return canLoadScript ? (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-1071913488"
        async
        onLoad={() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          function gtag(...args: any[]) {
            window.dataLayer.push(args);
          }
          if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            gtag('js', new Date());
            gtag('config', 'AW-1071913488');
            gtag('event', 'conversion', {
              send_to: 'AW-1071913488/mLuLCOjszgIQkLSQ_wM',
              aw_remarketing_only: true
            });
          }
        }}
      />
      <div className="inline">
        <img
          height="1"
          width="1"
          alt=""
          className="border-none"
          id="googlePixel"
          src={
            '//googleads.g.doubleclick.net/pagead/viewthroughconversion/1071913488/?value=1.00&amp;' +
            'currency_code=USD&amp;label=rM_2CKLx824QkLSQ_wM&amp;guid=ON&amp;script=0'
          }
        />
      </div>
    </>
  ) : (
    <></>
  );
}
